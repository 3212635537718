import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Dropdown, Field, Fieldset, Input } from 'components';
import { FieldState } from 'components/formField/field';
import { InputContainer } from 'components/input/input.style';
import { CountriesDrawer } from 'features/app/components/countries';
import { Drawer } from 'features/app/components/drawer';
import { COMPANY_REGISTRATION_COUNTRIES_LIST } from 'features/application/forms/company/constants/';
import { selectCustomerInformation } from 'features/auth/selectors/';
import { appendCustomerInformation, setActiveCompany, setActiveCustomer } from 'features/auth/slice';
import { useNavContext } from 'features/nav/context/navContext';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks/';
import { RequestCreateCustomer } from 'services/RbacService/typings';
import { refreshAuthLogic } from 'services/RestClient';
import { CustomBaseQueryError } from 'services/RestClient/typings';
import { getCountryDisplayName } from 'translations/countries';
import { Languages } from 'translations/translations';
import { companyCodeRequired, companyNameRequired, countryRequired } from 'validators';

import { useCreateCustomerMutation } from '../../slice';

import { CreateCompanyDrawerExplanation } from './createCompanyDrawer.styles';

const defaultValues: RequestCreateCustomer = {
  customerCode: '',
  customerCountry: '',
  customerName: '',
  customerCrmCode: '',
  customerType: 'BUSINESS'
};

type ValidationSchema = Yup.ObjectSchema<Record<keyof RequestCreateCustomer, Yup.AnySchema>>;

export const validationSchema: ValidationSchema = Yup.object().shape({
  customerCode: companyCodeRequired,
  customerCountry: countryRequired,
  customerName: companyNameRequired,
  customerType: Yup.string().required(),
  customerId: Yup.string().optional(),
  customerCrmCode: Yup.string().optional()
});

const CreateCompanyDrawer = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const { hideSideDrawer, showRightSideDrawer, toggleRightSideDrawer } = useNavContext();

  const [showCountriesDrawer, setShowCountriesDrawer] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companyFieldState, setCompanyFieldState] = useState<FieldState>({} as FieldState);
  const [companyCrmFieldState, setCompanyCrmFieldState] = useState<FieldState>({} as FieldState);

  const activeCustomer = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);

  const [triggerCustomerCreation] = useCreateCustomerMutation();

  const { validationHelpers, getValues, handleSubmit, setTouchedOnAll, reset } =
    useFormHandlers<RequestCreateCustomer>(defaultValues, validationSchema);

  const handleCountrySelect = (value: string) => {
    validationHelpers.setValueWithoutValidation('customerCountry', value);
    setShowCountriesDrawer(false);
  };

  const handleClose = () => {
    hideSideDrawer?.();
  };

  const handleSave = handleSubmit(
    async (payload) => {
      try {
        setCompanyFieldState({} as FieldState);
        setCompanyCrmFieldState({} as FieldState);
        setIsSubmitting(true);

        if (
          payload.customerCrmCode &&
          activeCustomer?.customers?.some((customer) => customer.customerCrmId === payload.customerCrmCode)
        ) {
          const queryError: CustomBaseQueryError = {
            status: 400,
            data: {
              message: 'CUSTOMER_CRM_ID_ALREADY_EXIST'
            }
          };

          throw queryError;
        }

        await triggerCustomerCreation(payload).unwrap();

        const customerInfo = {
          userAgreementAccepted: false,
          customerId: payload.customerCode,
          customerName: payload.customerName,
          customerCountryId: payload.customerCountry,
          customerCrmId: payload.customerCrmCode,
          roles: ['VERIFIED', 'READ', 'MANAGE_USERS', 'SALES_ADMIN']
        };

        dispatch(appendCustomerInformation(customerInfo));

        await refreshAuthLogic(null, payload.customerCode, true);

        dispatch(setActiveCustomer(customerInfo));
        dispatch(
          setActiveCompany({
            id: customerInfo.customerId,
            name: customerInfo.customerName,
            country: customerInfo.customerCountryId,
            roles: customerInfo.roles
          })
        );

        toggleRightSideDrawer?.({ drawerId: 'createCompanySuccess' });
      } catch (e) {
        const baseQueryError = e as CustomBaseQueryError<{ id?: string; message?: string }>;

        if (
          baseQueryError?.status === 400 &&
          ['CUSTOMER_ALREADY_EXIST', 'CUSTOMER_CRM_ID_ALREADY_EXIST'].includes(
            baseQueryError.data?.message ?? ''
          )
        ) {
          const fieldState = {
            isDirty: true,
            isTouched: true,
            message: t('companyAlreadyExists'),
            error: {
              message: t('companyAlreadyExists'),
              type: 'manual'
            },
            invalid: true,
            showValidationMessage: true
          };

          if (baseQueryError.data?.message === 'CUSTOMER_ALREADY_EXIST') {
            setCompanyFieldState(fieldState);
          } else {
            setCompanyCrmFieldState(fieldState);
          }
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    (errors) => {
      setCompanyFieldState({
        isDirty: true,
        isTouched: true,
        error: errors.customerCode,
        message: errors.customerCode?.message
      });
      setCompanyCrmFieldState({
        isDirty: true,
        isTouched: true,
        error: errors.customerCrmCode,
        message: errors.customerCrmCode?.message
      });
      setTouchedOnAll();
    }
  );

  useEffect(() => {
    reset(defaultValues);
    setCompanyFieldState({} as FieldState);
    setCompanyCrmFieldState({} as FieldState);
  }, [showRightSideDrawer]);

  const companyRegistrationCountryOptions = COMPANY_REGISTRATION_COUNTRIES_LIST.map((isoCode) => ({
    value: isoCode.value,
    label: getCountryDisplayName(isoCode.value, i18n.language as Languages)
  }));

  return (
    <>
      <CountriesDrawer
        activeIndex={1}
        searchable={false}
        open={showCountriesDrawer}
        onClose={() => setShowCountriesDrawer(false)}
        onSelect={handleCountrySelect}
        selectedValue={getValues('customerCountry')}
        whitelist={COMPANY_REGISTRATION_COUNTRIES_LIST.map((country) => country.value)}
        drawerHeader={t('placeholderCompanyCountry')}
      />
      <Drawer
        isValid
        dataTestId="create-company"
        isSubmitting={isSubmitting}
        header={t('createNewCompany')}
        onSave={handleSave}
        disabledDelete
        onClose={handleClose}
        buttonLabel={t('createCompany')}
      >
        <Fieldset>
          <InputContainer>
            <CreateCompanyDrawerExplanation>
              {t('createNewCompanyDialogExplanation')}
            </CreateCompanyDrawerExplanation>
          </InputContainer>
          <Field
            testId="company-country"
            name="customerCountry"
            Component={Dropdown}
            separateFromMenu
            onClick={() => setShowCountriesDrawer(true)}
            validateOnChange
            displayFlag
            options={companyRegistrationCountryOptions}
            validationHelpers={validationHelpers}
            placeholder={t('placeholderCompanyCountry')}
            value={getValues('customerCountry')}
            required
          />
          <Field
            testId="company-name"
            name="customerName"
            Component={Input}
            validationHelpers={validationHelpers}
            placeholder={t('placeholderCompanyName')}
            value={getValues('customerName')}
            required
          />
          <Field
            testId="company-code"
            name="customerCode"
            Component={Input}
            validationMeta={companyFieldState}
            validationHelpers={validationHelpers}
            placeholder={t('placeholderCompanyCode')}
            value={getValues('customerCode')}
            required
          />
          <Field
            testId="company-crm-code"
            name="customerCrmCode"
            Component={Input}
            validationMeta={companyCrmFieldState}
            validationHelpers={validationHelpers}
            placeholder={t('crmId')}
            value={getValues('customerCrmCode')}
          />
          <Input disabled value={t('business')} required readOnly placeholder={t('customerType')} />
        </Fieldset>
      </Drawer>
    </>
  );
};

export default CreateCompanyDrawer;
