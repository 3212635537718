import { useTranslation } from 'react-i18next';

import pencil from 'assets/icons/pencilOutline.svg';
import { LinkButton } from 'components';
import { useNavContext } from 'features/nav/context/navContext';

import { EditableInputView } from './editableField.styles';

type Props = {
  value?: string;
  label?: string;
  readonly?: boolean;
  editDrawerId?: string;
};

export const EditableField = ({ value, label, readonly, editDrawerId }: Props) => {
  const { t } = useTranslation();

  const { toggleRightSideDrawer } = useNavContext();

  const onEditClick = () => {
    toggleRightSideDrawer?.({ drawerId: editDrawerId });
  };

  return (
    <>
      <EditableInputView label={t(label ?? '')}>
        {value ?? '-'}
        {!readonly ? (
          <LinkButton
            data-testid={`${editDrawerId}-button`}
            color="Blue"
            onClick={onEditClick}
            iconOnly
            icons={{ left: pencil }}
          />
        ) : null}
      </EditableInputView>
    </>
  );
};
