import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { paths } from 'services/RbacService/schema/';
import {
  RequestCreateCustomer,
  RequestCreateRelation,
  RequestCreateUser,
  RequestDeleteRelation,
  RequestDeleteUser,
  RequestGetCustomer,
  RequestGetRelation,
  RequestGetUser,
  RequestGetUserByEmail,
  RequestGetUsers,
  RequestPatchRelation,
  RequestPatchUser,
  RequestUpdateCustomer,
  RequestUpdateUser,
  ResponseCreateCustomer,
  ResponseCreateRelation,
  ResponseCreateUser,
  ResponseDeleteRelation,
  ResponseDeleteUser,
  ResponseGetCustomer,
  ResponseGetRelation,
  ResponseGetUser,
  ResponseGetUserByEmail,
  ResponseGetUsers,
  ResponsePatchRelation,
  ResponsePatchUser,
  ResponseUpdateCustomer,
  ResponseUpdateUser,
  Role,
  UserBasicData
} from 'services/RbacService/typings';
import { axiosBaseQuery } from 'services/RestClient/axiosBaseQuery';
import { typedBaseUrlBuilder } from 'services/utils/baseUrlBuilder';

const withBaseUrl = typedBaseUrlBuilder<paths>('rbac');

const convertRoleToSortWeight = (user: UserBasicData): number => {
  const role = user.roles?.[0]?.role as Role;

  switch (role) {
    case 'FULL_CONTROL':
      return 50;
    case 'APPROVER':
      return 40;
    case 'EDITOR':
      return 30;
    case 'VIEWER':
      return 20;
  }
};

const customerUsersApi = createApi({
  reducerPath: 'users',
  baseQuery: axiosBaseQuery,
  tagTypes: ['user', 'users', 'userByEmail', 'relations', 'skip_invalidation'],
  endpoints: (build) => ({
    getUsers: build.query<ResponseGetUsers, RequestGetUsers>({
      query: () => ({
        url: withBaseUrl('/v1/management/customer/users')
      }),
      providesTags: ['users'],
      transformResponse: (response) => {
        const typedResponse = response as ResponseGetUsers;

        const sortedUser = typedResponse.users
          ? [...typedResponse.users].sort((a, b) => {
              return convertRoleToSortWeight(b) - convertRoleToSortWeight(a);
            })
          : typedResponse.users;

        return { ...typedResponse, users: sortedUser };
      }
    }),
    getUser: build.query<ResponseGetUser, RequestGetUser>({
      query: (payload) => ({
        url: withBaseUrl('/v1/management/user/{id}', payload)
      }),
      providesTags: ['user']
    }),
    getUserByEmail: build.query<ResponseGetUserByEmail, RequestGetUserByEmail>({
      query: () => ({
        url: withBaseUrl('/v1/management/user')
      }),
      providesTags: ['userByEmail']
    }),
    createUser: build.mutation<ResponseCreateUser, RequestCreateUser>({
      query: (payload) => ({
        url: withBaseUrl('/v1/management/user/with-relation', undefined, { roleId: payload.roleId }),
        method: 'POST',
        data: payload.content['application/json']
      }),
      invalidatesTags: (_, error) => [
        !error ? 'relations' : 'skip_invalidation',
        !error ? 'users' : 'skip_invalidation'
      ]
    }),
    updateUser: build.mutation<ResponseUpdateUser, RequestUpdateUser>({
      query: (payload) => ({
        url: withBaseUrl('/v1/management/user/with-relation', undefined, {
          roleId: payload.roleId,
          email: payload.email
        }),
        method: 'PUT',
        data: payload.content['application/json']
      }),
      invalidatesTags: (_, error) => [
        !error ? 'relations' : 'skip_invalidation',
        !error ? 'users' : 'skip_invalidation',
        !error ? 'userByEmail' : 'skip_invalidation'
      ]
    }),
    getRelations: build.query<ResponseGetRelation, RequestGetRelation>({
      query: (payload) => ({ url: withBaseUrl('/v1/management/relation', undefined, payload) }),
      providesTags: ['relations']
    }),
    createRelations: build.mutation<ResponseCreateRelation, RequestCreateRelation>({
      query: (payload) => ({
        url: withBaseUrl('/v1/management/relation'),
        method: 'POST',
        data: payload
      }),
      invalidatesTags: (_, error) => [
        !error ? 'relations' : 'skip_invalidation',
        !error ? 'users' : 'skip_invalidation'
      ]
    }),
    deleteUser: build.mutation<ResponseDeleteUser, RequestDeleteUser>({
      query: (payload: RequestDeleteUser) => ({
        url: withBaseUrl('/v1/management/user/with-relation/{email}', payload),
        method: 'DELETE'
      }),
      invalidatesTags: (_, error) => [
        !error ? 'relations' : 'skip_invalidation',
        !error ? 'users' : 'skip_invalidation'
      ]
    }),
    createCustomer: build.mutation<ResponseCreateCustomer, RequestCreateCustomer>({
      query: (payload) => ({
        url: withBaseUrl('/v1/management/customer'),
        method: 'POST',
        data: payload
      })
    }),
    getCustomer: build.query<ResponseGetCustomer, RequestGetCustomer>({
      query: (payload: RequestGetCustomer) => ({
        url: withBaseUrl('/v1/management/customer/{country}/{companyCode}', payload),
        method: 'GET'
      })
    }),
    deleteCustomer: build.mutation<ResponseDeleteUser, RequestDeleteUser>({
      query: (payload: RequestDeleteUser) => ({
        url: withBaseUrl('/v1/management/customer/{country}/{companyCode}', payload),
        method: 'DELETE'
      })
    }),
    patchCustomer: build.mutation<ResponseUpdateCustomer, RequestUpdateCustomer>({
      query: (payload) => ({
        url: withBaseUrl('/v1/management/customer/{country}/{companyCode}', payload),
        method: 'PUT',
        data: payload.content['application/json']
      }),
      invalidatesTags: (_, error) => [
        !error ? 'relations' : 'skip_invalidation',
        !error ? 'users' : 'skip_invalidation'
      ]
    }),
    patchRelation: build.mutation<ResponsePatchRelation, RequestPatchRelation>({
      query: (payload: RequestPatchRelation) => ({
        url: withBaseUrl('/v1/management/relation/{id}', payload),
        method: 'PATCH',
        data: payload.content['application/json']
      }),
      invalidatesTags: (_, error) => [
        !error ? 'relations' : 'skip_invalidation',
        !error ? 'users' : 'skip_invalidation'
      ]
    }),
    deleteRelation: build.mutation<ResponseDeleteRelation, RequestDeleteRelation>({
      query: (payload: RequestDeleteRelation) => ({
        url: withBaseUrl('/v1/management/relation/{id}', payload),
        method: 'DELETE'
      }),
      invalidatesTags: (_, error) => [
        !error ? 'relations' : 'skip_invalidation',
        !error ? 'users' : 'skip_invalidation'
      ]
    }),
    patchUser: build.mutation<ResponsePatchUser, RequestPatchUser>({
      query: (payload: RequestPatchUser) => ({
        url: withBaseUrl('/v1/management/user'),
        method: 'PATCH',
        data: payload
      }),
      invalidatesTags: (_, error) => [
        !error ? 'relations' : 'skip_invalidation',
        !error ? 'users' : 'skip_invalidation',
        !error ? 'userByEmail' : 'skip_invalidation'
      ]
    })
  })
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useGetRelationsQuery,
  useDeleteUserMutation,
  useLazyGetRelationsQuery,
  useLazyGetUserByEmailQuery,
  useLazyGetUsersQuery,
  useGetUserByEmailQuery,
  useGetCustomerQuery,
  usePatchCustomerMutation,
  useCreateCustomerMutation,
  useCreateUserMutation,
  useCreateRelationsMutation,
  useDeleteCustomerMutation,
  usePatchRelationMutation,
  useUpdateUserMutation,
  usePatchUserMutation,
  endpoints
} = customerUsersApi;

export const { resetApiState: resetUsersApiState } = customerUsersApi.util;

export default customerUsersApi;
