import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import exclamation from 'assets/icons/exclamationCircle.svg';
import eye from 'assets/icons/eye.svg';
import pencil from 'assets/icons/pencilOutline.svg';
import spaner from 'assets/icons/spaner.svg';
import tickedBox from 'assets/icons/tickedBox.svg';
import { LinkButton } from 'components';

import {
  RolesWrapper,
  RoleWrapper,
  ListOption,
  ListOptionExplanationButton,
  List,
  Title,
  RoleName,
  PermissionDescription,
  PermissionIncludedIcon
} from './roles.styles';

export enum ROLES {
  admin = 1,
  viewer,
  editor,
  approver
}

export type UserRole = 'approver' | 'editor' | 'admin' | 'viewer' | '';

type Permissions =
  | 'viewContent'
  | 'uploadInvoices'
  | 'approveInvoices'
  | 'signContracts'
  | 'manageContracts'
  | 'inviteAndEditUserRoles'
  | 'addBuyer';

type RolesProps = {
  label: UserRole;
  permissions: Permissions[];
};

export const roles: RolesProps[] = [
  {
    label: 'admin',
    permissions: [
      'viewContent',
      'uploadInvoices',
      'addBuyer',
      'approveInvoices',
      'signContracts',
      'manageContracts',
      'inviteAndEditUserRoles'
    ]
  },
  {
    label: 'approver',
    permissions: [
      'viewContent',
      'uploadInvoices',
      'addBuyer',
      'approveInvoices',
      'signContracts',
      'manageContracts'
    ]
  },
  {
    label: 'editor',
    permissions: ['viewContent', 'uploadInvoices', 'addBuyer']
  },
  {
    label: 'viewer',
    permissions: ['viewContent']
  }
];

type RolesType = { role?: string; roleId?: number };

export const userRole = (roles: RolesType[]): UserRole => {
  if (roles.find((role) => role.role === 'APPROVER')) {
    return 'approver';
  }

  if (roles.find((role) => role.role === 'EDITOR')) {
    return 'editor';
  }

  if (roles.find((role) => role.role === 'FULL_CONTROL')) {
    return 'admin';
  }

  return 'viewer';
};

type RolesRepresentationProps = {
  selectedRole?: UserRole;
};

export const Roles = ({ selectedRole }: RolesRepresentationProps) => {
  return (
    <>
      {roles
        .filter((role) => role.label === selectedRole || !selectedRole)
        .map((role, index) => (
          <Role key={index} roleName={role.label} />
        ))}
    </>
  );
};

type RoleProps = {
  roleName: UserRole;
};

export const Role: FC<RoleProps> = ({ roleName }) => {
  const { t } = useTranslation();

  const role = roles.find((role) => role.label === roleName);

  if (!role) {
    return null;
  }

  const fullPermissionsRole = roles.find((role) => role.label === 'admin');

  return (
    <RolesWrapper key={role.label}>
      <RoleName>{t(role.label)}</RoleName>
      {fullPermissionsRole?.permissions.map((permission) => {
        const includesPermission = role.permissions.includes(permission);

        return (
          <RoleWrapper key={permission}>
            <PermissionIncludedIcon included={includesPermission} />
            <PermissionDescription>{t(`permissions.${permission}`)}</PermissionDescription>
          </RoleWrapper>
        );
      })}
    </RolesWrapper>
  );
};

type UserRolesGroupProps = {
  selectedRole: UserRole | '';
  onChange: (string: UserRole) => unknown;
  invalid?: boolean;
  onInfoClick?: (selectedRole?: UserRole) => unknown;
};

type OptionProps = {
  role: UserRole;
  selectedRole: UserRole | '';
  onChange: (string: UserRole) => unknown;
  onInfoClick?: (selectedRole?: UserRole) => unknown;
};

const roleIcons = {
  approver: tickedBox,
  editor: pencil,
  admin: spaner,
  viewer: eye
};

const Option = ({ role, selectedRole, onChange, onInfoClick }: OptionProps) => {
  const { t } = useTranslation();

  return (
    <LinkButton
      data-testid={`user-role-option-${role}`}
      color="Black"
      iconOnly
      onClick={() => onChange(role)}
    >
      <ListOption
        checked={selectedRole === role}
        icon={roleIcons[role as keyof typeof roleIcons]}
        title={t(role)}
      >
        <ListOptionExplanationButton
          icon={exclamation}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onInfoClick?.(role);
          }}
        />
      </ListOption>
    </LinkButton>
  );
};

export const UserRolesGroup: FC<UserRolesGroupProps> = ({ selectedRole, onChange, onInfoClick }) => {
  const { t } = useTranslation();

  return (
    <List>
      <Title>
        {t('userRole')}
        <ListOptionExplanationButton icon={exclamation} onClick={() => onInfoClick?.()} />
      </Title>
      {roles.map((role) => (
        <Option
          key={role.label}
          role={role.label}
          selectedRole={selectedRole}
          onChange={onChange}
          onInfoClick={onInfoClick}
        />
      ))}
    </List>
  );
};
