import { useTranslation } from 'react-i18next';

import { Column, Grid } from 'components';
import { selectActiveCustomer, selectIsCustomerAdmin } from 'features/auth/selectors';
import { EditableField } from 'features/users/components';
import { useAuthSelector } from 'hooks/redux/hooks';
import { getCountryDisplayName } from 'translations/countries';
import { Languages } from 'translations/translations';

import { ContentContainer } from './company.styles';

const Company = () => {
  const { i18n } = useTranslation();

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const isAdmin = useAuthSelector<typeof selectIsCustomerAdmin>(selectIsCustomerAdmin);

  const { customerName, customerId, customerCountryId, customerCrmId } = activeCustomer || {};
  const countryName = getCountryDisplayName(customerCountryId || '', i18n.language as Languages);

  const disableEdit = !isAdmin;

  return (
    <ContentContainer data-testid="settings-company-info">
      <Grid>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <EditableField
            readonly={disableEdit}
            label="companyName"
            value={customerName}
            editDrawerId="editCompanyName"
          />
        </Column>
        {isAdmin ? (
          <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
            <EditableField
              readonly={disableEdit}
              label="crmId"
              value={customerCrmId}
              editDrawerId="editCrmId"
            />
          </Column>
        ) : null}
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <EditableField
            readonly
            label="placeholderCompanyCode"
            value={customerId}
            editDrawerId="editCompanyCode"
          />
        </Column>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <EditableField readonly label="country" value={countryName} />
        </Column>
      </Grid>
    </ContentContainer>
  );
};

export default Company;
